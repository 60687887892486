import axios from 'axios';
import 'moment/locale/en-gb.js';
import 'moment/locale/ru.js';
import 'moment/locale/uk.js';
import * as moment from "moment";

export const instance = axios.create({
    // TODO CHANE URL
    baseURL: 'https://edelweiss.bukovel.com/'
});


export const initLng = (lng) => {
    const defaultLng = 'uk';


    // прокидаємо муву в бібліотеку момент.js
    moment.locale(lng || defaultLng);

    // для всіх існуючих інстенсів аксіоса прокидаю хедер мови (мову меру з path url /uk /en /ru )
    instance.defaults.headers['Accept-Language'] = lng;
};



// refetch after change language
export const refetchPageData = (nextProps, thisProps, callback) => {
    // попередні параметри
    const {match = {}} = thisProps;
    const {params = {}} = match;
    const {lng: thisLng} = params;

    // параметри які прийшли
    const {match: nextMatch} = nextProps;
    const {params: nextParams} = nextMatch;
    const {lng: nextLng} = nextParams;

    if (thisLng !== nextLng) {
        if (callback) {
            const defaultLng = 'uk';

            // переключаю мову для бібліотеки момент
            moment.locale(nextLng || defaultLng);

            // переключаю статичнку мову
            // i18n.changeLanguage(nextLng || defaultLng);

            // змінюю херери для посилання на бекенд
            initLng(nextLng || defaultLng);

            // в колбеці прокидається функції fetch
            callback()
        }
    }
};


export const fetchData = (url, options) => {
    return new Promise((resolve, reject) => {
        instance.get(url, options)
            .then(result => {
                resolve(result.data);
            })

            .catch(error => {
                if (error.response && error.response.data) {
                    reject(error.response);
                }

            });
    });
};