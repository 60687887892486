import React,{Component} from "react";
import "./Header.scss";
import Menu from "../../components/Menu/Menu";

import Slider from "react-slick";


class Header extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {sliders = [], shown_prices, add_blocks = []} = this.props;
        // const {showIcon} = this.state;
        const settings = {
            dots: false,
            arrow:true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <header className="header">
                <div className="header__shadow"  > </div>
                <div className="header-cover__image" style={{backgroundImage: `url("/images/mobile_cover.jpeg")`}}> </div>

                {sliders.length ? <div className="header__carousel">
                    <Slider {...settings}>
                        {
                            sliders.map((slide,index) => {
                                return (
                                    <div key={index}>
                                        <div className="header__background" style={{backgroundImage:`url('${slide.img}')`}}></div>
                                    </div>
                                )
                            })
                        }

                    </Slider>
                </div> : null}
                <Menu shown_prices={shown_prices} add_blocks={add_blocks}/>
            </header>
        )
    }
}

export default Header