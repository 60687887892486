import React from "react";
import "./Footer.scss";
import {
  IconFacebook,
  IconInstagram,
  IconTripAdvisor,
  IconBooking,
} from "../../components/UI/Icons/Icons";
import * as moment from "moment";
import {withI18n} from "react-i18next";


const Footer = (props) => {
  const {t} = props;
  return (
    <footer className="footer" name="contacts">
      <div className="footer__wrapper">

        <div className="footer__column">
          <div className="footer__blocks">
            <div className="footer__left">
              <div>
                <div style={{fontSize: 19}}>{t('Бронювання готелю')}</div>
                <a href="tel:+380342591100" className="footer__phone">+380 342 591 100</a>
              </div>
              <div className="footer__address">
                E-mail: <a href="mailto: web@bukovel.com">web@bukovel.com</a>
              </div>
              <br/>
              <div>
                <div style={{fontSize: 19}}>{t('Бронювання для корпоративних груп')}</div>


                <a href="tel:+380671043989" className="footer__phone"> +38 067 104 39 89</a>
                <br/>
                <a href="tel:+380342591100" className="footer__phone">+380 342 59 11 00</a>
                <div className="footer__address">
                  E-mail: <a href="mailto: sales_hotel@bukovel.com">sales_hotel@bukovel.com</a>
                </div>
              </div>
              <br/>
              <br/>
              <span className="footer__address">{t('адрес')}</span><br/>

              <div className="footer__icons">
                <a className="icon"
                   href="https://www.tripadvisor.com/Hotel_Review-g1816350-d3431451-Reviews-Edelveis_Hotel-Yaremche_Ivano_Frankivsk_Oblast.html"
                   target="_blank">
                  <IconTripAdvisor/>
                </a>
                <a className="icon"
                   href="https://www.facebook.com/people/Edelweiss-%D0%9A%D0%BE%D0%BD%D1%84%D0%B5%D1%80%D0%B5%D0%BD%D1%86-%D0%B3%D0%BE%D1%82%D0%B5%D0%BB%D1%8C-%D0%B2-%D0%AF%D1%80%D0%B5%D0%BC%D1%87%D0%B5/61560208324444/"
                   target="_blank">
                  <IconFacebook/>
                </a>
                <a className="icon"
                   href="https://www.instagram.com/edelweiss_yaremche/?igsh=aW9sdXJ0eG9pc3kz"
                   target="_blank">
                  <IconInstagram/>
                </a>


              </div>

            </div>
          </div>

          <div className="footer__bottom">
            <div className="footer__info">
              {t('Готель «Едельвейс»')} {moment().format('YYYY')} <br/>
              {t('Офіційний сайт')}
            </div>
            <div className="footer__info">

            </div>
          </div>
        </div>


      </div>
      <div className="footer__background" style={{backgroundImage: `url('/images/footer.jpg')`}}></div>
      <div className="footer__shadow left"></div>
      <div className="footer__shadow right"></div>
      <div className="footer__shadow top"></div>
    </footer>
  )

};

export default withI18n()(Footer);