import React, {Component} from "react";
import "./Location.scss";
import TitleWithDescription from "../UI/TitleWithDescription/TitleWithDescription";

class Location extends Component {

  render() {
    const {location = {}, name} = this.props;

    return (
      <div className="location" name={name}>
        <div className="location__left">
          <TitleWithDescription
            title={location.title}
            description={location.descr}
          />
        </div>

          <div className="location__right">
            <a
              href="https://www.google.com/maps/place/%D0%95%D0%B4%D0%B5%D0%BB%D1%8C%D0%B2%D0%B5%D0%B9%D1%81/@48.430788,24.5311296,15z/data=!4m8!3m7!1s0x473724121f3c82ad:0xe8e40b3edc9fc1a7!5m2!4m1!1i2!8m2!3d48.4338467!4d24.5373933"
              target="_blank">
              <div className="location__img"
                   style={{backgroundImage: 'url("/images/background/location.jpg")'}}></div>
            </a>
          </div>
      </div>
    )
  }
}


export default Location;